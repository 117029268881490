import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { UserModel } from "../../_models/user.model";
import { environment } from "../../../../../environments/environment";
import {
  AuthModel,
  IStageResponseModel,
  IStageUserModel,
} from "../../_models/auth.model";
import { AppHttpClient } from "src/app/core/services/http-call/http-call.service";

const API_USERS_URL = `${environment.apiUrl}v1/hiring`;

@Injectable({
  providedIn: "root",
})
export class AuthHTTPService {
  constructor(private http: AppHttpClient) { }

  // public methods
  login(email: string, password: string): Observable<any> {
    return this.http.post<AuthModel>(`${API_USERS_URL}/login`, {
      userName: email,
      password,
    }, null, false);
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: IStageUserModel): Observable<UserModel> {
    return this.http.post<UserModel>(`${API_USERS_URL}/register`, user, null, false);
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string, firstName: string, lastName: string): Observable<boolean> {
    return this.http.post<boolean>(`${API_USERS_URL}/email/forgot-password`, {
      email,
      firstName,
      lastName,
    }, null, false);
  }

  updateProfile(profileDetails: UserModel) {
    return this.http.put<boolean>(`${API_USERS_URL}/profile`, profileDetails, null, false);
  }

  changePassword(oldPassword: string, newPassword: string) {
    return this.http.post<boolean>(`${API_USERS_URL}/changePassword`, {
      oldPassword,
      newPassword,
    }, null, false);
  }

  stageUser(user: IStageUserModel) {
    return this.http.post<IStageResponseModel>(`${API_USERS_URL}/stage`, user, null, false);
  }

  resetPassword(tokenId: string, password: string, otp: string, email: string) {
    return this.http.post<boolean>(`${API_USERS_URL}/email/forgot-password/reset`, {
      tokenId,
      password,
      otp,
      email,
    }, null, false);
  }

  verifyResetOtp(userName: string, tokenId: string, otp: string) {
    return this.http.post<boolean>(`${API_USERS_URL}/email/forgot-password/verify-otp`, {
      userName,
      tokenId,
      otp,
    }, null, false);
  }

  resendResetOtp(firstName: string, lastName: string, tokenId: string) {
    return this.http.post<boolean>(`${API_USERS_URL}/email/forgot-password/resend-otp`, {
      firstName,
      lastName,
      tokenId,
    }, null, false);
  }

  getUserByToken(token): Observable<UserModel> {
    return of(JSON.parse(localStorage.getItem(token)));
  }

  sendEmailToken(user: IStageUserModel): Observable<{ tokenId: string }> {
    return this.http.post<{ tokenId: string }>(
      `${API_USERS_URL}/email/otp`,
      user,
      null, false
    );
  }

  sendMobileToken(user: IStageUserModel): Observable<{ tokenId: string }> {
    return this.http.post<{ tokenId: string }>(
      `${API_USERS_URL}/mobile/otp`,
      user, null, false
    );
  }

  resendEmailToken(user: IStageUserModel): Observable<{ tokenId: string }> {
    return this.http.post<{ tokenId: string }>(
      `${API_USERS_URL}/email/otp/resend`,
      user, null, false
    );
  }

  resendMobileToken(user: IStageUserModel): Observable<{ tokenId: string }> {
    return this.http.post<{ tokenId: string }>(
      `${API_USERS_URL}/mobile/otp/resend`,
      user, null, false
    );
  }

  verifyEmailToken(
    userName: string,
    tokenId: string,
    otp: string
  ): Observable<{ isVerified: boolean }> {
    return this.http.post(`${API_USERS_URL}/email/otp/verify`, {
      userName,
      tokenId,
      otp,
    }, null, false);
  }

  verifyMobileToken(
    userName: string,
    tokenId: string,
    otp: string
  ): Observable<{ isVerified: boolean }> {
    return this.http.post(`${API_USERS_URL}/mobile/otp/verify`, {
      userName,
      tokenId,
      otp,
    }, null, false);
  }
}
